import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "bootstrap/dist/css/bootstrap.min.css"
import { Badge, Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import _ from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookF,
  faLinkedinIn,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES, BLOCKS, MARKS } from "@contentful/rich-text-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Post = ({ data }) => {
  const posts = data?.contentfulBlogPost
  const ogImage = posts?.ogImage?.url
  const SEO = JSON.parse(posts?.seo?.internal?.content)

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <b className="font-bold">{text}</b>,
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        const { uri } = node.data
        return (
          <a href={uri} className="underline">
            {children}
          </a>
        )
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return <h3>{children}</h3>
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2>{children}</h2>
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <p className="mb-4">{children}</p>
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        return <ul>{children}</ul>
      },
      [BLOCKS.HR]: (node, children) => {
        return <hr />
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { gatsbyImageData, description } = node.data.target
        return (
          <GatsbyImage
            style={{
              background:
                "repeating-linear-gradient(245deg, transparent, #d7a613 900px)",
            }}
            class="rounded mt-5 mb-5"
            image={getImage(gatsbyImageData)}
            alt={description}
          />
        )
      },
    },
  }

  const postScript = () => {
    return (
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "NewsArticle",
          headline: posts?.title,
          description: SEO?.description,
          image: [ogImage],
          datePublished: posts?.publishedDate,
          dateModified: posts?.publishedDate,
          author: SEO?.author,
          publisher: SEO?.publisher,
        })}
      </script>
    )
  }
  return (
    <Layout>
      <Seo
        title={posts?.title}
        scripts={postScript()}
        meta={[
          {
            property: `og:image`,
            content: ogImage,
          },
          {
            name: `twitter:image`,
            content: ogImage,
          },
        ]}
        description={SEO?.description}
      />
      <Container
        className="text-white py-5"
        fluid
        style={{
          background:
            "repeating-linear-gradient(0deg, transparent, #d7a613 1200px)",
        }}
      >
        <Row className="justify-content-center py-5">
          <Col md="12" className="text-center">
            <h1 className="minimal-title fs-1 lh-1">{posts?.title}</h1>
          </Col>
        </Row>
      </Container>
      <section className="py-5 container">
        <Container className="text-white">
          <Row className="justify-content-md-between">
            <Col md="7" className="order-1 order-md-0">
              {renderRichText(posts?.richContentBody, options)}
            </Col>
            <Col md="4" className="order-0 order-md-1">
              <div className="d-flex align-items-center mb-2">
                <GatsbyImage
                  image={getImage(posts?.authorThumbnail)}
                  style={{ width: 58, height: 58 }}
                  className="rounded-circle"
                  alt="blog image"
                />
                <p className="mb-0 ms-2 minimal-body fw-bold">
                  {posts?.author}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <p className="mb-0 minimal-body">PUBLISHED</p>
                <p className="mb-0 minimal-body">{posts?.publishedDate}</p>
              </div>
              <div className="d-flex align-items-center justify-content-between mt-3">
                <p className="mb-0 minimal-body">TAGS</p>
                <div
                  className="d-flex align-content-start flex-wrap ms-5"
                  style={{
                    gridColumnGap: "0.3rem",
                    gridRowGap: "0.5rem",
                  }}
                >
                  {posts?.tags?.tags?.map(tag => (
                    <Badge
                      key={tag?.ref}
                      bg="light"
                      className="small text-dark text-center text-uppercase rounded-pill"
                    >
                      {tag?.text}
                    </Badge>
                  ))}
                </div>
              </div>
              <hr />
              <div className="d-flex align-items-center justify-content-between">
                <p>SHARE</p>
                <div
                  className="d-flex align-content-start"
                  style={{
                    gridColumnGap: "1.2rem",
                  }}
                >
                  <a
                    className="twitter-share-button"
                    href={
                      "https://twitter.com/intent/tweet?text=" +
                      SEO?.description +
                      "&url=https://tawalap.com/" +
                      posts?.slug?.slug
                    }
                    data-size="large"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faXTwitter}
                      className="text-light fa-2x"
                    />
                  </a>
                  <a
                    href={
                      "https://www.facebook.com/sharer.php?u=" +
                      "https://tawalap.com/" +
                      posts?.slug?.slug
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faFacebookF}
                      className="text-light fa-2x"
                    />
                  </a>

                  <a
                    href={
                      "https://www.linkedin.com/shareArticle?url=" +
                      "https://tawalap.com/" +
                      posts?.slug?.slug
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faLinkedinIn}
                      className="text-light fa-2x"
                    />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}
export default Post

export const query = graphql`
  query ($slug: String!) {
    contentfulBlogPost(slug: { slug: { eq: $slug } }) {
      id
      author
      title
      node_locale
      slug {
        slug
      }
      publishedDate(fromNow: false, formatString: "MMMM D, yyyy")
      category
      tags {
        tags {
          ref
          text
        }
        internal {
          content
        }
      }
      richContentBody {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData(width: 1200, height: 750, quality: 100)
            __typename
          }
        }
      }
      authorThumbnail {
        url
        gatsbyImageData(width: 200)
      }
      contentful_id
      ogImage {
        url
      }
      seo {
        internal {
          content
        }
      }
    }
  }
`
